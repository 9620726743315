// Border radius
@mixin border-radius($radius) {
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	-ms-border-radius: $radius;
	border-radius: $radius;
}

// Transition
@mixin transition($args...) {
	-webkit-transition: $args;
	-moz-transition: $args;
	-ms-transition: $args;
	-o-transition: $args;
	transition: $args;
}

// Opacity
@mixin opacity($opacity) {
	opacity: $opacity;
	$opacity-ie: $opacity * 100;
	filter: alpha(opacity=$opacity-ie); //IE8
}

// Box sizing
@mixin box-sizing($box-model) {
	-webkit-box-sizing: $box-model; // Safari <= 5
	-moz-box-sizing: $box-model; // Firefox <= 19
	box-sizing: $box-model;
}

// Center block
@mixin center-block {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

// Font size
@mixin font-size($sizeValue: 12 ){
	font-size: $sizeValue + px; //fallback for old browsers
	font-size: (0.125 * $sizeValue) + rem;
}

// Line height
@mixin line-height($heightValue: 12 ){
    line-height: $heightValue + px; //fallback for old browsers
    line-height: (0.125 * $heightValue) + rem;
}