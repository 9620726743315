// Imports
@import "bootstrap";
@import "animate";
@import "animations";
@import "grid";
@import "mixins";

html {
	overflow-x: hidden;
}

// Hide me effect
.hideme,.hideme_left,.hideme_right,.hideme_down,.hideme_up {opacity: 0;}

.grey-bg {
	background-color: #f5f5f5;
}

.display {display: block!important;}
.none {display: block!important;}

// Camera icon
.camera_icon {
	width: 55px;
	height: 55px;
	background-color: #000;
	margin: 0 auto;
	text-align: center;
	padding: 15px;
	margin-top: 50px;
	-webkit-transform: rotate(45deg);
	   -moz-transform: rotate(45deg);
	    -ms-transform: rotate(45deg);
	     -o-transform: rotate(45deg);
	        transform: rotate(45deg);
	img {
		width: auto;
		height: 16px;
		-webkit-transform: rotate(-45deg);
		   -moz-transform: rotate(-45deg);
		    -ms-transform: rotate(-45deg);
		     -o-transform: rotate(-45deg);
		        transform: rotate(-45deg);
	}
}

// Annifont
@font-face {
    font-family: 'annifont';
    src: url('../fonts/annifont-webfont.eot');
    src: url('../fonts/annifont-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/annifont-webfont.woff2') format('woff2'),
         url('../fonts/annifont-webfont.woff') format('woff'),
         url('../fonts/annifont-webfont.ttf') format('truetype'),
         url('../fonts/annifont-webfont.svg#annifontitalic') format('svg');
    font-weight: normal;
    font-style: normal;
}

body {
	font-family: "brandon-grotesque";
	font-size: 16px;
	color: #000;
	font-weight: 300;
	padding-top: 77px;

  .sitekick {
	padding-top: 77px;

	.top_bar, .c-navigation {
	  top: 70px;
	}
  }
}

h1,h2,h3,h4,h5 {
	margin: 0px;
	padding: 0px;
	font-weight: 900; // Black
	letter-spacing: 10px;
	text-transform: uppercase;
	word-wrap: break-word;

	@media (max-width: 992px) {
		letter-spacing: 1px;
	}

	@media (max-width: 1200px) {
		letter-spacing: 7px;
	}
}

img {
	height: auto;
}

// section titles
.section_titles, .section_title {
  padding-top: 100px;
  padding-bottom: 100px;
  text-align: center;

  background-image: url('../img/cross_line.png');
  background-size: 190px 190px;
  background-position: center center;
  background-repeat: no-repeat;

  h2 {
	color: #000;
	font-size: 26px;
	font-family: 'annifont';
	margin-bottom: 15px;
	margin-left: 50px;
	font-weight: 300;
	text-transform: uppercase;
	  word-wrap: break-word;
  }
  h1 {
	color: #000;
	font-size: 47px;
	text-transform: uppercase;
	  word-wrap: break-word;
  }

	@media (max-width: 992px) {
		padding-top: 50px;
		padding-bottom: 50px;
		background-size: 125px 125px;

		h2 {
			font-size: 18px;
			margin-left: 0px;
		}
		h1 {
			font-size: 24px;
		}
	}
}

p {
	font-size: 16px;
	text-transform: uppercase;
	font-weight: 300;
	margin: 0;
	letter-spacing: 5px;
	line-height: 25px;
}

a {
	color: #000;
}

// Input
input[type="text"],input[type="email"],input[type="password"],textarea {
	width: 100%;
	height: auto;
	background-color: #FFF;
	border: 4px solid #000;
	outline: 0;
	outline-style: none;
	padding: 8px 15px;
	font-size: 16px;
	text-transform: uppercase;
	font-weight: 900;
	color: #000;
	letter-spacing: 3px;
	margin-bottom: 10px;
}

// Vertical line
.v-line {
	width: 1px;
	margin: 0 auto;
	height: 100px;
	background-color: #bdbdbd;
}

.v-line-camera {
	height: 250px;
	width: 105px;
	margin: 75px auto;
	background: url('../img/icon_photo.svg') no-repeat bottom center;
	background-size: 105px 250px;
}

// Button
.button {
	display: inline-block;
	border: 4px solid #000;
	padding: 8px 20px;
	background-color: #FFF;
	font-weight: 900;
	font-size: 18px;
	text-transform: uppercase;
	outline: 0;
	outline-style: none;
	letter-spacing: 3px;
	color: #000;
	&:hover {
		color: #000;
		text-decoration: none;
	}
}

// Top bar
.top_bar {
	width: 100%;
	height: auto;
	padding: 22px 0 25px 0;
	background: url("../img/hr_line.png") repeat-x center bottom #fff;
	position: fixed;
	z-index: 99;
	top: 0;
	left: 0;
	right: 0;

	&.home {
		background: #fff;
		padding-bottom: 22px;
	}

	//@media (min-width: 992px) {
	//	.left-padding {
	//		left: -15px;
	//	}
	//}

	// Logo
	.logo {
		width: 330px;
		float: left;
	}

	// Navicon
	.navicon {
		width: 45px;
		height: 45px;
		background-image: url('../img/navicon.svg');
		background-size: 100% 100%;
		float: right;
		cursor: pointer;
	}
}

// Navigation
.c-navigation {
	position: fixed;
	width: 100%;
	height: 100vh;
	background-image: url('../img/bg_menu.jpg');
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	z-index: 9999;
	top: 0;
	left: 0;
	display: none;

	.mediacontainer {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0; left: 0; bottom: 0; right: 0;
	}

	// Copyright
	.copyright {
		font-weight: 400;
		font-size: 14px;
		position: absolute;
		left: 0;
		bottom: 0;
		display: block;
		width: 200px;
		text-transform: uppercase;
		letter-spacing: 2px;
		-webkit-transform: rotate(-90deg);
		-ms-transform: rotate(-90deg);
		transform: rotate(-90deg);
		-webkit-transform-origin: left top;
		-ms-transform-origin: left top;
		transform-origin: left top;
	}

	.social {
		padding: 0;
		margin: 0;
		list-style-type: none;
		position: absolute;
		right: 15px;
		bottom: 0;
		display: block;
		width: 250px;
		-webkit-transform: rotate(90deg);
		-ms-transform: rotate(90deg);
		transform: rotate(90deg);
		-webkit-transform-origin: bottom right;
		-ms-transform-origin: bottom right;
		transform-origin: bottom right;

		li {
			display: inline-block;
			//transform: rotate(90deg);
			margin: 0;
			padding: 0 0 0 30px;
			font-size: 14px;
			font-weight: 400;
			text-transform: uppercase;
			background-size: 20px 20px;
			background-position: left center;
			background-repeat: no-repeat;
			transition: all .3s;

			&:hover {
				background-position: 3% center;
				a {
					color: #000;
					text-decoration: none;
				}
			}
			&:first-child {
				background-image: url('../img/icon_instagram.svg');
				margin-right: 30px;
			}
			&:last-child {
				background-image: url('../img/icon_linkedin.svg');
			}
		}
	}

	// Top
	.top {
		padding-top: 30px;
		padding-bottom: 30px;

		// Logo
		.logo {
			width: 330px;
			float:left;
		}

		// Close
		.navicon-close {
			float: right;
			font-weight: 900;
			font-size: 25px;
			color: #000;
			text-transform: uppercase;
			letter-spacing: 5px;
			background-image: url('../img/close.svg');
			background-size: 25px 25px;
			background-repeat: no-repeat;
			background-position: right center;
			padding-right: 35px;
			cursor: pointer;
		}
	}

	// Main nav
	.main-nav {
		list-style-type: none;
		margin: 0 auto;
		padding: 0;
		margin-top: 10%;
		line-height: 0;
		// display: none;
		li {
			display: block;

			&:hover {
				a {
					background-color: #000;
					color: #FFF;
					padding-left: 80px;
					background-position: 3% center;
				}
			}

			a {
				font-size: 70px;
				line-height: 1;
				color: #000;
				font-weight: 900;
				text-transform: uppercase;
				letter-spacing: 10px;
				text-decoration: none;
				//line-height: 85px;
				line-height: 70px;
				padding: 0px 15px;
				transition: all .3s;
				background-image: url('../img/arrow_right_white.svg');
				background-size: 50px 50px;
				background-position: -50px center;
				background-repeat: no-repeat;
			}
		}
	}
}

// Slider section
.slider_section {
	position: relative;
	// Copyright
	.copyright {
		font-weight: 400;
		font-size: 14px;
		text-align: right;
		position: absolute;
		left: -40px;
		top: 245px;
		display: block;
		//width: 200px;
		text-transform: uppercase;
		letter-spacing: 2px;
		-webkit-transform: rotate(-90deg);
		-ms-transform: rotate(-90deg);
		transform: rotate(-90deg);
		-webkit-transform-origin: left top;
		-ms-transform-origin: left top;
		transform-origin: left top;
	}

	.social {
		padding: 0;
		margin: 0;
		list-style-type: none;
		position: absolute;
		right: -25px;
		bottom: 0;
		-webkit-transform: rotate(90deg);
		-ms-transform: rotate(90deg);
		transform: rotate(90deg);
		-webkit-transform-origin: bottom right;
		-ms-transform-origin: bottom right;
		transform-origin: bottom right;

		li {
			display: inline-block;
			//transform: rotate(90deg);
			margin: 0;
			padding: 0 0 0 30px;
			font-size: 14px;
			font-weight: 400;
			text-transform: uppercase;
			background-size: 20px 20px;
			background-position: left center;
			background-repeat: no-repeat;
			transition: all .3s;

			&:hover {
				background-position: 3% center;
				a {
					color: #000;
					text-decoration: none;
				}
			}
			&:first-child {
				background-image: url('../img/icon_instagram.svg');
				margin-right: 30px;
			}
			&:last-child {
				background-image: url('../img/icon_linkedin.svg');
			}
		}
	}
}

// Home slider
.home_slider {
	width: 100%;
	height: 850px;
	height: 78vh;
	position: relative;

	.header_image {
		width: 100%;
		height: 100%;
		display: block;
		background: no-repeat center center;
		background-size: cover;
		//background: no-repeat 70% center;
		position: absolute;
		top: 0; left: 0; bottom: 0; right: 0;
		opacity: 0;
		transition: all .8s ease;
		-webkit-transition: all .8s ease;

		&.active {
			opacity: 1;
		}
	}

	// Slides
	&.slide1 {background-image: url('../img/home_slider1.jpg');}

  	.slider_navigation {
		position: absolute;
	 	right: 2%;
	  	top: 50%;
		margin-top: -55px;

		//@media (min-width: 768px) {
		//	right: 65px;
		//}

	  li {
		width: 15px;
		height: 15px;
		margin: 17px 0;
		display: block;
		border: 3px solid #ffffff;
		-ms-transform: rotate(45deg);
		-webkit-transform: rotate(45deg);
		transform: rotate(45deg);
		cursor: pointer;
		transition: ease .2s all;
		  -webkit-transition: ease .2s all;

		&.active, &:hover {
		  background: #ffffff;
		}
	  }
	}

	// Slider content
	.slider_content {
		word-break: break-word;
		padding: 20px;
		max-width: 100%;
		bottom: 0;
		left: 0;
		position: absolute;

		@media (min-width: 768px) {
			margin: 0 60px;
			left: 5%;
			bottom: 10%;
			//left: 130px;
		}

		@media (min-width: 992px) {
			//bottom: 110px;
		}

		.borders {
			padding: 40px 0 0 0;
			//margin: 0 40px;
			//padding: 20px 0 0 0;
			display: inline-block;
			position: relative;
			overflow: hidden;

			@media (min-width: 768px) {
				//padding: 40px 30px 0 30px;
				overflow: visible;

				&:before, &:after {
					content: " ";
					width: 40px;
					height: 100%;
					margin-left: -25px;
					/* padding: 20px; */
					display: block;
					border: 10px solid #fff;
					border-right: 0;
					position: absolute;
					top: -6px;
					/* margin-right: 30px; */
					left: -35px;

					@media (min-width: 992px) {
						width: 60px;
						border: 15px solid #fff;
						border-right: 0;
					}
				}

				&:after {
					right: -20px;
					left: auto;
					border: 10px solid #fff;
					border-left: 0;

					@media (min-width: 992px) {
						width: 60px;
						border: 15px solid #fff;
						border-left: 0;
					}
				}
			}
		}

		// H1
		h1 {
			font-size: 28px;
			color: #FFF;
		  	display: inline-block;
		  	text-transform: uppercase;
			text-shadow: 1px 1px 5px rgba(0,0,0,0.3);
			animation-duration: 1.5s;

			@media (min-width: 992px) {
				font-size: 48px;
			}

			@media (min-width: 1200px) {
				font-size: 70px;
			}
		}
		//h2
		h2 {
			font-size: 15px;
			color: #FFF;
			font-family: 'annifont',sans-serif;
			font-weight: 300;
			text-shadow: 1px 1px 5px rgba(0,0,0,0.3);
			margin: 15px 0;
		  	display: inline-block;
			animation-duration: 1.5s;
			letter-spacing: 5px;

			@media (min-width: 992px) {
				font-size: 25px;
				//margin: 15px 0 15px 50px;
			}
			
			@media (min-width: 1200px) {
				font-size: 30px;
				margin: 15px 0 15px 50px;
			}
		}
		// Button
		.button {
			background-color: #FFF;
			font-size: 16px;
			font-weight: 900;
			letter-spacing: 5px;
			margin: 0;
			display: inline-block;
			text-transform: uppercase;
			color: #000;
			text-decoration: none;
			background-image: url('../img/arrow_right.svg');
			background-size: 20px 20px;
			background-repeat: no-repeat;
			background-position: 93% center;
			padding: 10px 40px 10px 25px;
			animation-duration: 1s;
			animation-delay: 1.5s;
			border: none;

			@media (min-width: 992px) {
				margin: 0 15px;
			}
		}
	}
}

// Home slider padding
.home_slider_padding {
	padding-top: 40px;
	padding-bottom: 40px;
	position: relative;
}

// Description
.description {
	list-style-type: none;
	margin: 0;
	padding: 0;
	background-image: url('../img/arrow_right.svg');
	background-size: 40px 40px;
	background-position: left center;
	background-repeat: no-repeat;
	padding-left: 60px;

	li {
		font-size: 14px;
		color: #000;
		text-transform: uppercase;
		letter-spacing: 2px;
		border-left: 2px solid #000;
		font-weight: 500;
		padding-left: 15px;
		max-width: 360px;
	}
	
	a {
		text-decoration: underline;
	}
}

//Scroll
@keyframes bounce-background {
	from {
		background-position: center 25px;
	}
	50% {
		background-position: center 32px;
	}
	to {
		background-position: center 25px;
	}
}

.scroll {
	margin: 0 auto;
	color: #000;
	text-transform: uppercase;
	letter-spacing: 2px;
	font-weight: 500;
	font-size: 14px;
	position: absolute;
    top: 20%;
    left: 50%;
    right: 50%;
    background-image: url('../img/arrow_down.svg');
    background-size: 5px 30px;
    background-position: center 25px;
    background-repeat: no-repeat;
    height: 100px;
    width: 61px;
	animation-name: bounce-background;
	animation-timing-function: ease-in-out;
	animation-duration: 1.5s;
	animation-iteration-count: infinite;
	-webkit-animation-name: bounce-background;
	-webkit-animation-timing-function: ease-in-out;
	-webkit-animation-duration: 1.5s;
	-webkit-animation-iteration-count:infinite;
}

// Project highlight
.project_highlight {
	width: 100%;
	height: 1060px;
//	background-image: url('../img/bg_image1.jpg');
//	background-position: center center;
//	background-size: cover;
//	background-repeat: no-repeat;
	position: relative;
	
	.backgroundImage {
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		position: absolute;
		width: 100%;
		height: 100%;
		opacity: .1;
	}
	
	img {
		width: 100%;
		max-width: 778px;
	}

	//.section_titles {
	//	h1 {
	//		font-size: 42px;
	//	}
	//	h2 {
	//		font-size: 23px;
	//	}
	//}

	// Info box
	.info_box {
		padding: 50px 30px;
		border: 7px solid #1c1c1d;
		// background:transparent;
		display: inline-block;
		max-width: 430px;
		position: absolute;
		bottom: -50px;
		right: 0px;
		text-align: center;
		background: rgba(255,255,255,1);
		background: -moz-linear-gradient(left, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 10%, rgba(255,255,255,0.59) 47%, rgba(255,255,255,0) 100%);
		background: -webkit-gradient(left top, right top, color-stop(0%, rgba(255,255,255,1)), color-stop(10%, rgba(255,255,255,1)), color-stop(47%, rgba(255,255,255,0.59)), color-stop(100%, rgba(255,255,255,0)));
		background: -webkit-linear-gradient(left, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 10%, rgba(255,255,255,0.59) 47%, rgba(255,255,255,0) 100%);
		background: -o-linear-gradient(left, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 10%, rgba(255,255,255,0.59) 47%, rgba(255,255,255,0) 100%);
		background: -ms-linear-gradient(left, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 10%, rgba(255,255,255,0.59) 47%, rgba(255,255,255,0) 100%);
		background: linear-gradient(to right, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 10%, rgba(255,255,255,0.59) 47%, rgba(255,255,255,0) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1 );

		.button {
			margin-top: 20px;
			background-color: transparent;
		}
		h3 {
			font-size: 35px;
			line-height: 50px;
			margin-bottom: 30px;
		}
		p {
			margin: 0;
			font-size: 16px;
			font-weight: 300;
			letter-spacing: 2px;
			text-transform: uppercase;
			line-height: 35px;
		}
	}
}


.c-opdrachtgevers {
  img {
	width: 100%;
  }

  .brands img {
	margin: 0 0 50px 0;
  }

  .grayscale img {
	filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale"); /* Firefox 10+, Firefox on Android */
	filter: gray; /* IE6-9 */
	-webkit-filter: grayscale(100%); /* Chrome 19+, Safari 6+, Safari 6+ iOS */
  }
}


// C portfolio
.c-portfolio {
	width: 100%;
	height: auto;
  	padding-top: 35px;
	padding-bottom: 100px;

	img {
		width: 100%;
		max-width: 563px;
	}
	.description {
		margin-top: 30px;
		margin-bottom: 30px;
		li {
			font-size: 12px;
		}
	}

	.more_projects {
		margin-top: 70px;
		text-align: center;
		h3 {
			font-size: 27px;
			font-weight: 500;
		}
	}
	.v-line {
		margin-top: 30px;
		margin-bottom: 30px;
	}
	.portfolio-item {
		position: relative;
	}
	.portfolio-overlay {
		position:absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		border: 16px solid rgba(0,0,0,0.8);
		background-color: rgba(255,255,255,.7);
		text-align: center;
		padding-top: 100px;
		opacity: 0;
		transition: all .3s;
		cursor: pointer;

		h1 {
			margin: 0 20px;
		}
		&.top_second {
			padding-top: 50px;
		}
		&.top_third {
			padding-top: 30px;
			.v-line {
				display: none;
			}
			.button {margin-top: 30px;}

			h1 {
				font-size: 28px;
			}
		}
		&.small-border {
			border-width: 10px;
			h1 {
				font-size: 20px;
				margin: 0 20px;
			}
		}
		.button {
			background-color: transparent;

			@media (max-width: 1200px) {
				padding: 8px 10px;
			}
		}
		&:hover {
			opacity: 1;
			h1 {
				-webkit-animation-name: fadeInDown;
  				animation-name: fadeInDown;
  				-webkit-animation-duration: 1s;
				animation-duration: 1s;
				-webkit-animation-fill-mode: both;
				animation-fill-mode: both;
			}
			.button {
				-webkit-animation-name: fadeInUp;
  				animation-name: fadeInUp;
  				-webkit-animation-duration: 1s;
				animation-duration: 1s;
				-webkit-animation-fill-mode: both;
				animation-fill-mode: both;
			}
			.v-line {
				-webkit-animation-name: fadeIn;
  				animation-name: fadeIn;
  				-webkit-animation-duration: 1s;
				animation-duration: 1s;
				-webkit-animation-fill-mode: both;
				animation-fill-mode: both;
			}
		}
	}
}

// About
.about {
	width: 100%;
	height: auto;
	background-image: url('../img/hr_line.png');
	background-size: 5px 3px;
	background-position: left top;
	background-repeat: repeat-x;
	text-align: center;
	p {
		font-size: 16px;
		text-transform: uppercase;
		font-weight: 300;
		margin: 0;
		letter-spacing: 5px;
		line-height: 30px;
	}
	.section_titles {
		padding-bottom: 30px;
		padding-top: 30px;
		margin-top: 50px;
		margin-bottom: 30px;
		//h1 {
		//	font-size: 35px;
		//}
	}
	h3 {
		font-family: 'annifont',sans-serif;
		font-size: 24px;
		font-weight: 300;
		letter-spacing: 5px;
		margin-top: 80px;

		@media (max-width: 992px) {
			font-size: 14px;
			line-height: 1.5;
			letter-spacing: 4px;
		}
	}
	.v-line {
		height: 200px;
		margin-top: 80px;
		margin-bottom: 80px;
	}
	.camera_box {
		position: absolute;
		left: 50%;
		right: 50%;
		top:25%;
		// width: 70px;
		// height: 70px;
		background-color: #000;
		padding: 20px;
		// background-image: url('../img/icon_camera_white.svg');
		// background-size: 20px 20px;
		// background-position: center center;
		// background-repeat: no-repeat;
		-webkit-transform: rotate(45deg);
		   -moz-transform: rotate(45deg);
		    -ms-transform: rotate(45deg);
		     -o-transform: rotate(45deg);
		        transform: rotate(45deg);
		img {
			width: 25px;
			height: 25px;
			-webkit-transform: rotate(-45deg);
			   -moz-transform: rotate(-45deg);
			    -ms-transform: rotate(-45deg);
			     -o-transform: rotate(-45deg);
			        transform: rotate(-45deg);
		}
	}
	.profile-picture {
		width: 100%;
		max-width: 481px;
		margin: 0 auto;
	}
}

// Footer
.footer {
	width: 100%;
	padding-top: 3px;
	background-color: #f1f1f1;
	background-image: url('../img/hr_line.png');
	background-size: 5px 3px;
	background-repeat: repeat-x;
	background-position: left top;
	text-align:center;
	word-break: break-word;
	
	.col-sm-6 {
		padding-top: 50px;
		padding-bottom: 50px;
	}

	.copyright {
		text-align: right;
		margin: 10px 0;
		font-size: 13px;
	}

	.background {
		min-height: 330px;
		background: url('../img/footer_bg.jpg') no-repeat top center;
		-webkit-background-size: cover;
		background-size: cover;
	}

	.logo {
		width: 100%;
		max-width: 380px;
		margin-bottom: 10px;
	}

	p {
		letter-spacing: 3px;
	}
	h1 {
		font-size: 28px;
		margin-bottom: 30px;
		letter-spacing: 5px;
	}
	.contact_list {
		list-style-type: none;
		margin: 0;
		padding: 0;
		margin-top: 10px;

		li {
			padding-left: 25px;
			font-size: 16px;
			text-transform: uppercase;
			font-weight: 300;
			letter-spacing: 3px;
			line-height: 25px;
			margin-top: 20px;
			margin-bottom: 20px;
			span {
				background-size: 17px 17px;
				background-position: left center;
				background-repeat: no-repeat;
				vertical-align: bottom;
				width: 19px;
				height: 17px;
				display: inline-block;
				margin-bottom: 4px;
				margin-right: 10px;
			}
			span.phone {background-image: url('../img/phone.svg');}
			span.mail {background-image: url('../img/mail.png');
				background-size: 19px 16px;}
		}
	}
	.social_list {
		list-style-type: none;
		margin: 0;
		padding: 0;
		li {display: inline;margin-right: 15px;}
		li a img {
			width: 30px;
			height: 30px;
		}
	}
	.button {
		color:#FFF;
		background-color: #000;
		margin-top: 120px;
		padding: 5px 15px;
		&.send {
			width: 60%;
			background-image: url('../img/plane_white.svg');
			background-position: 5% center;
			background-size: 20px 20px;
			background-repeat: no-repeat;
			cursor: pointer;
		}
	}
	input,textarea {
		width: 60%;
		resize: none;
	}
	.icons {
		margin-top: 80px;
		img {
			width: 30px;
			height: 30px;
			margin-right: 10px;
			margin-left: 10px;
		}
	}
}

// Portfolio detail
.portfolio_detail {
	padding-top: 80px;
	padding-bottom: 80px;

	img {
		width: 100%;
	}

	.imageContainer {
		overflow: hidden;
	}

	.contentContainer {
		padding-left: 50px;

		.button {
			display: inline-block;
			margin: 15px 0;
		}

		@media (max-width: 992px) {
			padding: 15px;
		}
	}

	.scroll_bar {
		margin-top: 40px;
		margin-bottom: 20px;
		// margin-bottom: 20px;
	}

	.scroll {
		position: relative;
		left: auto;
		right: auto;
		bottom: auto;
		top: auto;
		margin: 0 auto;
	}

	p {
		margin-bottom: 30px;
	}

	.section_titles {
		padding-top: 0px;
		padding-bottom: 0px;
		margin-bottom: 30px;
		text-align: left;
		background-position: 30% center;

		h2 {
			//font-size: 19px;
			margin-left: 0px;
			margin-top: 25px;
		}
		h1 {
			//font-size: 50px;
		}
	}

	@media (max-width: 992px) {
		//.section_titles {
		//	margin: 20px 0 0 -35px;
		//}
		//
		//p {
		//	margin: 20px 0 0 -30px;
		//}
	}

  .galleryPhoto {
	margin-top: 90px;
  }
}

// Text page
.text_page {
	text-align:center;
	// padding-bottom: 80px;

	//.section_titles {
	//	h1 {
	//		font-size: 35px;
	//	}
	//}

	.camera_icon {
		margin-top: 80px;
		margin-bottom: 80px;
	}

	h3 {
		font-family: 'annifont',sans-serif;
		font-size: 24px;
		font-weight: 300;
		margin-bottom: 80px;
	}
	p {
		margin-bottom: 30px;
	}
	.image-grey {
		margin-top: 100px;
	}
}

.picture_icon_bg {
	margin-bottom: 70px;
	padding-bottom: 280px;
	background: url('../img/icon_photo.svg') no-repeat bottom center;
	background-size: 105px 250px;
}

.spacer {
  background: url('../img/spacer_bg.svg') no-repeat bottom center;
  background-size: 1px 100px;
  padding-bottom: 70px;
  margin-bottom: 50px;
}

.mark_bg {
	padding-bottom: 550px;
	background: url('../img/mark.jpg') no-repeat bottom center;
	background-size: 481px 474px;

	@media (max-width: 992px) {
		padding-bottom: 230px;
		background-size: 70%;
	}
}

// Principals
.principals {
	padding-bottom: 80px;
	.section_titles {
		padding-top: 30px;
		padding-bottom: 30px;
		margin-top: 50px;
		//h1 {
		//	font-size: 35px;
		//}
		//h2 {
		//	font-size: 23px;
		//}
	}
	img {
		width: 100%;
		max-width: 351px;
	}
}

// Contact
.contact {
	padding-top: 80px;
	padding-bottom: 80px;
	text-align:center;

	.logo {
		max-width: 500px;
		width: 100%;
	}

	p {
		font-size: 16px;
		text-transform: uppercase;
		margin-top: 15px;
	}
	.contact_list {
		list-style-type: none;
		margin: 0;
		padding: 0;
		margin-top: 30px;
		margin-bottom: 10px;

		li {
			padding-left: 25px;
			font-size: 16px;
			text-transform: uppercase;
			font-weight: 300;
			letter-spacing: 3px;
			line-height: 25px;
			margin-top: 20px;
			margin-bottom: 20px;

			span {
				background-size: 17px 17px;
				background-position: left center;
				background-repeat: no-repeat;
				vertical-align: bottom;
				width: 19px;
				height: 17px;
				display: inline-block;
				margin-bottom: 4px;
				margin-right: 10px;
			}
			span.phone {background-image: url('../img/phone.svg');}
			span.mail {background-image: url('../img/mail.png');
				background-size: 19px 16px;}

			@media (max-width: 992px) {
				font-size: 13px;
				letter-spacing: 1px;
				padding-left: 0;

				span {
					margin-right: 8px;
				}
			}
		}
	}
	h1 {
		font-family: 'annifont',sans-serif;
		font-weight: 300;
		font-size: 16px;
		margin-bottom: 15px;
	}
	h2 {
		font-size: 30px;
	}
	.social {
		margin-top: 15px;
		margin-bottom: 50px;
		img {
			width: 30px;
			height: 30px;
			margin-left: 10px;
			margin-right: 10px;
		}
	}
	.contact_list_second {
		list-style-type: none;
		margin: 0;
		padding: 0;
		margin-top: 30px;
		margin-bottom: 10px;

		li {
			padding-left: 25px;
			font-size: 16px;
			text-transform: uppercase;
			font-weight: 300;
			letter-spacing: 3px;
			line-height: 25px;
			margin-top: 20px;
			margin-bottom: 20px;
			span {
				background-size: 17px 17px;
				background-position: left center;
				background-repeat: no-repeat;
				vertical-align: bottom;
				width: 19px;
				height: 17px;
				display: inline-block;
				margin-bottom: 4px;
				margin-right: 10px;
			}
			span.global {background-image: url('../img/global.svg');}
			span.mail {background-image: url('../img/mail.png');
				background-size: 19px 16px;}
		}
	}
}

@media (max-width: 1300px) {
	.c-navigation .main-nav li a {
		font-size: 45px;
	}
	// Slider section
	.slider_section {

		.social {
			right: -30px;
		}
	}
}

@media (max-width: 1200px) {
	// Home slider
	//.home_slider {
	//	height: 650px;
	//
	//	// Slider content
	//	.slider_content {
	//		left: 70px;
	//	}
	//}

	// Project highlight
	.project_highlight {
		img {
			max-width: 578px;
		}
	}

	// Slider section
	.slider_section {

		.social {
			right: -50px;
		}
	}
}

@media (max-width: 992px) {
	//Scroll
	.scroll {
		display: none;
	}

	// Slider section
	.slider_section {

		.social,.copyright {
			display: none;
		}
	}

	// section titles
	.section_titles {
		padding-top: 50px;
		padding-bottom: 50px;
		background-size: 125px 125px;

		h2 {
			font-size: 13px;
			margin-left: 0px;
		}
		//h1 {
		//	font-size: 30px;
		//}
	}

	// Project highlight
	.project_highlight {
		height: auto;
		padding-bottom: 100px;
		text-align: center;
		background-position: 80% center;
		// background-image: none;
		// background: #FFF;

		img {
			max-width: 778px;
		}

		// Info box
		.info_box {
			padding: 30px 20px;
			position: relative;
			max-width: 90%;
			margin-top: -80px;
			background-color: #FFF!important;
			// margin: 0 auto;
		}
	}
}

@media (max-width: 768px) {
	// Body
	body {
		padding-top: 65px;
	}

	// Top bar
	.top_bar {
		
		// Logo
		.logo {
			width: 250px;
		}

		// Navicon
		.navicon {
			width: 35px;
			height: 35px;
		}
	}

	// Home slider
	//.home_slider {
	//	height: 350px;
	//
	//	// Slider content
	//	.slider_content {
	//		bottom: 30px;
	//		left: 20px;
	//		h1 {
	//			font-size: 25px;
	//		}
	//		h2 {
	//			font-size: 20px;
	//			padding: 0;
	//			margin: auto 0;
	//		}
	//	}
	//}

	// Description
	.description {

		li {
			font-size: 12px;
		}
	}

	// Home slider padding
	.home_slider_padding {
		padding-top: 20px;
		padding-bottom: 20px;
	}

	// Navigation
	.c-navigation {
		background-position: 70% center;

		// Top
		.top {
			padding-top: 15px;
			padding-bottom: 15px;

			// Logo
			.logo {
				width: 200px;
			}

			// Close
			.navicon-close {
				font-size: 18px;
				margin-top: 3px;
				padding-right: 25px;
				background-size: 20px 20px;
			}
		}

		// Main nav
		.main-nav {
			margin-top: 10%;
			text-align: center;
			li {

				a {
					font-size: 25px;
					line-height: 50px;
					letter-spacing: 5px;
					padding-left: 0px;
				}
			}
		}
	}

	// Project highlight
	.project_highlight {

		// Info box
		.info_box {
			padding: 20px 15px;
			h3 {
				font-size: 18px;
				margin-bottom: 20px;
				line-height: 25px;
				letter-spacing: 5px;
			}
			p {
				font-size: 14px;
				line-height: 27px;
			}
		}
	}

	// C portfolio
	.c-portfolio {
		padding-bottom: 50px;

		.more_projects {
			margin-top: 50px;
			h3 {
				font-size: 22px;
			}
		}
		.portfolio-overlay {
			display: none;
		}
	}
	
	// About
	.about {
		.section_titles {
			padding-bottom: 50px;
		}
		h3 {
			font-size: 22px;
			margin-top: 50px;
		}
		.v-line {
			height: 100px;
			margin-top: 50px;
			margin-bottom: 50px;
		}
	}

	// Portfolio detail
	.portfolio_detail {
		padding-top: 50px;
		padding-bottom: 50px;
	}
}