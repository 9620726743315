// Button effect slide
.button-effect-slide {
	overflow: hidden;
	// padding: 0 4px;
	// height: 1em;
}

.button-effect-slide span {
	position: relative;
	display: inline-block;
	-webkit-transition: -webkit-transform 0.3s;
	-moz-transition: -moz-transform 0.3s;
	transition: transform 0.3s;
}

.button-effect-slide span::before {
	position: absolute;
	top: 200%;
	content: attr(data-hover);
	font-weight: 900;
	-webkit-transform: translate3d(0,0,0);
	-moz-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
}

.button-effect-slide:hover span,
.button-effect-slide:focus span {
	-webkit-transform: translateY(-200%);
	-moz-transform: translateY(-200%);
	transform: translateY(-200%);
}

// Page fadein
body {
    animation: fadein 2s;
    -moz-animation: fadein 2s; /* Firefox */
    -webkit-animation: fadein 2s; /* Safari and Chrome */
    -o-animation: fadein 2s; /* Opera */
}
// Fadein
@keyframes fadein {
    from {opacity:0;}
    to {opacity:1;}
}
@-moz-keyframes fadein { /* Firefox */
    from {opacity:0;}
    to {opacity:1;}
}
@-webkit-keyframes fadein { /* Safari and Chrome */
    from {opacity:0;}
    to {opacity:1;}
}
@-o-keyframes fadein { /* Opera */
    from {opacity:0;}
    to {opacity:1;}
}